import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMap, faChevronUp, faBuilding, faHome } from '@fortawesome/free-solid-svg-icons';
import { FaApple, FaGooglePlay, FaHandHoldingHeart, FaPhoneAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import './styles/Home.css';

function Home() {
  const [formData, setFormData] = useState({ location: '' });
  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const searchResultsRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Nzubo | Find your next home';
  }, []);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setResult([]);
    try {
      const response = await axios.post('https://khah-184f534d3b81.herokuapp.com/api/aisearchestate', formData);
      const resultsArray = response.data.result.split('\n').filter((line) => line.trim() !== '');
      setResult(resultsArray);
    } catch (err) {
      setError('Something went wrong. Please try again.');
    } finally {
      setLoading(false);
      searchResultsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToTop = () => {
    document.getElementById('searchContainer').scrollIntoView({ behavior: 'smooth' });
  };

  const cards = [
    {
      id: 1,
      title: 'For Rent',
      description: '',
      icon: faBuilding,
      route: '/nyumbarent',
    },
    {
      id: 2,
      title: 'For Sale',
      description: '',
      icon: faHome,
      route: '/nyumbasales',
    },
  ];

  const handleNavigate = (route) => {
    navigate(route);
  };

  return (
    <div className="home-container">
      <header className="home-header">
        <h1>Find your next home</h1>
        <p>With Simplicity and Safety</p>
      </header>

      <main className="home-main">
        <form className="search-form" id="searchContainer" onSubmit={handleSubmit}>
          <label htmlFor="location" className="form-label">
            <FontAwesomeIcon icon={faMap} />{'\u00A0'}Enter a Location
          </label>
          <input
            type="text"
            id="location"
            name="location"
            value={formData.location}
            onChange={handleInputChange}
            placeholder="Search for homes near you..."
            className="form-input"
            required
          />
          <button type="submit" className="form-submit">
            {loading ? 'Searching...' : 'Search'}
          </button>
        </form>
        
        <div ref={searchResultsRef} className="search-results">
          {error && <p className="error-message">{error}</p>}
          {result.length > 0 && (
            <>
              <h2 className="search-title">Search Results using AI</h2>
              <div className="results">
                {result.map((item, index) => (
                  <p key={index} className="result-item">
                    {item}
                  </p>
                ))}
              </div>
            </>
          )}
        </div>

        <div className="responsive-cards-container">
          {cards.map((card) => (
            <div
              key={card.id}
              className="responsive-card"
              onClick={() => handleNavigate(card.route)}
              role="button"
              tabIndex={0}
              onKeyDown={(e) => e.key === 'Enter' && handleNavigate(card.route)}
            >
              <div className="responsive-card-icon">
                <FontAwesomeIcon icon={card.icon} />
              </div>
              <div className="responsive-card-content">
                <h3>{card.title}</h3>
                <p>{card.description}</p>
              </div>
            </div>
          ))}
        </div>

        <div className="download-buttons">
          <a href="https://nzubo.net/contact" target="_blank" rel="noreferrer" className="btn btn-ios">
            <FaHandHoldingHeart /> {'\u00A0'}Assistance
          </a>
          <a href="tel: +260777210641" target="_blank" rel="noreferrer" className="btn btn-android">
            <FaPhoneAlt />  {'\u00A0'}Call
          </a>
        </div>
      </main>

      <button className="floating-button" onClick={scrollToTop} aria-label="Scroll to top">
        <FontAwesomeIcon icon={faChevronUp} />
      </button>
    </div>
  );
}

export default Home;
