import React, { useState } from 'react';
import axios from 'axios';
import { FaCalendarAlt } from 'react-icons/fa';
import { Carousel } from 'react-responsive-carousel'; // Import the Carousel
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import Carousel styles
import { FaApple, FaGooglePlay, FaHandHoldingHeart, FaPhoneAlt } from 'react-icons/fa';
import './styles/HouseSaleForm.css'; // Import your CSS file for styling

import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

import { Switch, FormControlLabel, Typography } from "@mui/material";

// Fix for marker icons
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

const HouseSale = () => {
  const [formData, setFormData] = useState({
    location: '',
    description: '',
    numberOfBedrooms: 1,
    price: '',
    phoneNumber: '',
    nameOfManager: '',
    facilities: '',
    proximityToMalls: '',
    availableDate: '',
    images: [],
    typeOfNyumba: 'Stand-alone',
    sold: true,
    exclusive: false,
    latitude: -15.3875, // Default to Lusaka
    longitude: 28.3228,
    rating: 3,
  });

  const [selectedImages, setSelectedImages] = useState(null);
  const [loading, setLoading] = useState(false); // New state for spinner

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleMapClick = ({ lat, lng }) => {
    setFormData({
      ...formData,
      latitude: lat,
      longitude: lng,
    });
  };

  const MapClickHandler = () => {
    useMapEvents({
      click(e) {
        handleMapClick(e.latlng);
      },
    });
    return null;
  };

  delete L.Icon.Default.prototype._getIconUrl;

  L.Icon.Default.mergeOptions({
    iconRetinaUrl: markerIcon2x,
    iconUrl: markerIcon,
    shadowUrl: markerShadow,
  });

  const handleImageChange = (e) => {
    setSelectedImages(e.target.files);
  };

  const handleBookingChange = (e) => {
    setFormData({
      ...formData,
      isBooked: e.target.value === 'true', // Convert to boolean
    });
  };

  const handleRatingChange = (value) => {
    setFormData({
      ...formData,
      rating: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Show spinner when form submission starts
    const formDataToSend = new FormData();
    for (let i = 0; i < selectedImages.length; i++) {
      formDataToSend.append('images', selectedImages[i]);
    }
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }
    // https://khah-184f534d3b81.herokuapp.com      http://localhost:3004
    try {
      const response = await axios.post('https://khah-184f534d3b81.herokuapp.com/api/nyumbasale/create', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      alert(response.data.msg);
      // Reset form after successful submission
      setFormData({
        location: '',
        description: '',
        numberOfBedrooms: 1,
        price: '',
        phoneNumber: '',
        nameOfManager: '',
        facilities: '',
        proximityToMalls: '',
        availableDate: '',
        images: [],
        typeOfNyumba: 'Stand-alone',
        sold: true,
        exclusive: false,
        latitude: '',
        longitude: '',
        rating: 3,
      });
      setSelectedImages(null);
    } catch (error) {
      console.error(error);
      alert('Error posting the property');
    } finally {
      setLoading(false); // Hide spinner after form submission completes
    }
  };

  // Convert selected images to URLs for display in the carousel
  const imageUrls = selectedImages ? Array.from(selectedImages).map((file) => URL.createObjectURL(file)) : [];

  return (
    <form onSubmit={handleSubmit} className="private-home-form">
      <h2>Sale Your Property</h2> {/* Form title */}
      <div>
        <label>Location:</label>
        <input
          type="text"
          name="location"
          value={formData.location}
          onChange={handleChange}
          placeholder="Enter the location of your home" // Placeholder
          required
        />
      </div>
      <div>
        <label>Description:</label>
        <textarea
          name="description"
          value={formData.description}
          onChange={handleChange}
          placeholder="Provide a brief description of your home" // Placeholder
          required
        ></textarea>
      </div>
      <div>
        <label>Type of Property:</label>
        <select name="typeOfNyumba" value={formData.typeOfNyumba} onChange={handleChange}>
          <option value="Stand-alone">Stand-alone</option>
          <option value="Flat">Flat</option>
          <option value="Apartment">Apartment</option>
          <option value="Semi-detached">Semi-detached</option>
        </select>
      </div>
      <div>
        <label>Price($):</label>
        <input
          type="number"
          name="price"
          value={formData.price}
          onChange={handleChange}
          placeholder="Enter the price" // Placeholder
          required
        />
      </div>
      <div>
        <label>Phone Number:</label>
        <input
          type="tel"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
          placeholder="Enter your contact number" // Placeholder
          required
        />
      </div>
      <div>
        <label>Name of Manager:</label>
        <input
          type="text"
          name="nameOfManager"
          value={formData.nameOfManager}
          onChange={handleChange}
          placeholder="Enter your name" // Placeholder
          required
        />
      </div>
      <div>
        <label>Number of Bedrooms:</label>
        <input
          type="number"
          name="numberOfBedrooms"
          value={formData.numberOfBedrooms}
          onChange={handleChange}
          min="1"
          placeholder="1" // Placeholder
        />
      </div>
      <div>
        <label>Proximity to Malls (in km):</label>
        <input
          type="number"
          name="proximityToMalls"
          value={formData.proximityToMalls}
          onChange={handleChange}
          placeholder="Distance to nearest mall" // Placeholder
        />
      </div>
      <div>
        <label>
          Available Date:
          <input
            type="date"
            name="availableDate"
            value={formData.availableDate}
            onChange={handleChange}
            required
          />
          <FaCalendarAlt />
        </label>
      </div>

      <div className="checkbox-container">
        <label htmlFor="exclusive">
          <input
            type="checkbox"
            id="exclusive"
            name="exclusive"
            checked={formData.exclusive}
            onChange={handleChange}
          />
            Exclusive to Nzubo
        </label>
      </div>

      {/* <div>
        <label>Facilities:</label>
        <input
          type="text"
          name="facilities"
          value={formData.facilities}
          onChange={handleChange}
          placeholder="e.g., Wi-Fi, Kitchen, Parking" // Placeholder
        />
      </div> */}
      <div>

        <div>
          <label>Choose Location:</label>
          <MapContainer
            center={[formData.latitude, formData.longitude]}
            zoom={10}
            style={{ height: '300px', width: '100%' }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution="© OpenStreetMap contributors"
            />
            <Marker position={[formData.latitude, formData.longitude]} />
            <MapClickHandler />
          </MapContainer>
          <p>
            Selected Location: Latitude {formData.latitude}, Longitude {formData.longitude}
          </p>
        </div>

        <div>
          <label htmlFor="latitude">Latitude:</label>
          <input
            id="latitude"
            type="text"
            name="latitude"
            value={formData.latitude}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="longitude">Longitude:</label>
          <input
            id="longitude"
            type="text"
            name="longitude"
            value={formData.longitude}
            onChange={handleChange}
            required
          />
        </div>
      </div>
      {/* <div>
        <label>Is Booked:</label>
        <label>
          <input
            type="radio"
            name="isBooked"
            value="true"
            checked={formData.isBooked === true}
            onChange={handleBookingChange}
          />
          <span> Yes</span>
        </label>
        <label>
          <input
            type="radio"
            name="isBooked"
            value="false"
            checked={formData.isBooked === false}
            onChange={handleBookingChange}
          />
          <span> No</span>
        </label>
      </div> */}
      <div>
        <label>Rating:</label>
        <div className="star-rating">
          {[1, 2, 3, 4, 5].map((star) => (
            <span key={star} onClick={() => handleRatingChange(star)}>
              {star <= formData.rating ? '★' : '☆'}
            </span>
          ))}
        </div>
      </div>
      <div>
        <label>Upload Images:</label>
        <input
          type="file"
          accept="image/*"
          multiple
          onChange={handleImageChange}
          required
        />
      </div>
      {/* Carousel for selected images */}
      {imageUrls.length > 0 && (
        <div className="image-carousel">
          <Carousel showThumbs={false} infiniteLoop autoFocus>
            {imageUrls.map((url, index) => (
              <div key={index}>
                <img src={url} alt={`Selected ${index + 1}`} />
              </div>
            ))}
          </Carousel>
        </div>
      )}

      {/* Spinner and Submit Button */}
      <div className="submit-container">
        {loading ? (
          <div className="spinner"></div> // Display spinner when loading
        ) : (
          <button type="submit">Submit</button> // Show button when not loading
        )}
      </div>
      <div className="download-buttons">
        <a href="https://nzubo.net/contact" target="_blank" rel="noreferrer" className="btn btn-ios">
          <FaHandHoldingHeart /> {'\u00A0'}Assistance

        </a>
        <a href="tel: +260777210641" target="_blank" rel="noreferrer" className="btn btn-android">
          <FaPhoneAlt />  {'\u00A0'}Call
        </a>
      </div>
    </form>
  );
};

export default HouseSale;
