import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalfAlt, faStar as emptyStar } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter, faWhatsapp, faLinkedin } from '@fortawesome/free-brands-svg-icons'; // Include LinkedIn icon
import './styles/HouseSaleDetails.css'; // Add custom styles for the details page

const HouseSaleDetails = () => {
  const { id } = useParams();
  const [home, setHome] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios
      .get(`https://khah-184f534d3b81.herokuapp.com/api/nyumbasales/${id}`)
      .then((response) => {
        setHome(response.data);
      })
      .catch((err) => {
        setError('Failed to load home details. Please try again later.');
        console.error(err);
      });
  }, [id]);

  const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      if (i <= rating) {
        stars.push(<FontAwesomeIcon icon={faStar} key={i} className="star-icon" />);
      } else if (i === Math.ceil(rating) && rating % 1 !== 0) {
        stars.push(<FontAwesomeIcon icon={faStarHalfAlt} key={i} className="star-icon" />);
      } else {
        stars.push(<FontAwesomeIcon icon={emptyStar} key={i} className="star-icon empty" />);
      }
    }
    return stars;
  };

  const shareUrl = window.location.href; // Get the current page URL
  const message = `Check out this property at ${home?.location}. Price: $${home?.price}. Visit the link for more details: ${shareUrl}`;

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!home) {
    return <div>Loading...</div>;
  }

  return (
    <div className="home-details-container">
      <h2>{home.location}</h2>

      {/* Carousel for displaying all images */}
      <Carousel showThumbs={true} className="image-carousel">
        {home.images.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`Home image ${index + 1}`} className="img" />
          </div>
        ))}
      </Carousel>

      <div className="home-details-text">
        <p><strong>Location:</strong> {home.location}</p>
        <p><strong>Price:</strong> ${home.price}</p>
        <p><strong>Bedrooms:</strong> {home.numberOfBedrooms}</p>
        <p><strong>Description:</strong> {home.description}</p>
        <p><strong>Proximity to Malls:</strong> Within {home.proximityToMalls || 'Not specified'} km(s)</p>
        <p><strong>Personal Assistant:</strong> {home.assistName}</p>
        <p><strong>Assistant's Phone:</strong> {home.assistPhoneNumber}</p>
        <p><strong>Type of Home:</strong> {home.typeOfNyumba}</p>
        <p><strong>Rating:</strong> {renderStars(home.rating)}</p>

        <div className="home-details-actions">
          <a
            href={`mailto:khahtech2010@gmail.com?subject=Inquiiry about the property at ${home.location}&body=Hello, I would like to make an inquiry about the property located at ${home.location}. Could you please provide me with more details regarding availability and pricing?`}
            className="inquiry-button"
          >
            Inquire about this Property
          </a>
        </div>

        {/* Social Media Share Buttons */}
        <div className="social-share">
          <p>Share this property:</p>
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`}
            target="_blank"
            rel="noopener noreferrer"
            className="share-button facebook"
          >
            <FontAwesomeIcon icon={faFacebook} /> Facebook
          </a>
          <a
            href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(message)}`}
            target="_blank"
            rel="noopener noreferrer"
            className="share-button twitter"
          >
            <FontAwesomeIcon icon={faTwitter} /> Twitter
          </a>
          <a
            href={`https://wa.me/?text=${encodeURIComponent(message)}`}
            target="_blank"
            rel="noopener noreferrer"
            className="share-button whatsapp"
          >
            <FontAwesomeIcon icon={faWhatsapp} /> WhatsApp
          </a>
          <a
            href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareUrl)}`}
            target="_blank"
            rel="noopener noreferrer"
            className="share-button linkedin"
          >
            <FontAwesomeIcon icon={faLinkedin} /> LinkedIn
          </a>
        </div>
      </div>
    </div>
  );
};

export default HouseSaleDetails;
