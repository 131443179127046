import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ReactGA from "react-ga4";

import TrackPageView from "./TrackPageView";

import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';

import HouseSale from './pages/HouseSale';
import HouseSaleDetails from './components/details/HouseSaleDetails';
import HouseSaleForm from './components/create/HouseSaleForm';

import HouseRent from './pages/HouseRent';
import HouseRentDetails from './components/details/HouseRentDetails';
import HouseRentForm from './components/create/HouseRentForm';

import Request from './pages/Requests';
import RequestDetails from './components/details/RequestDetails';
import RequestForm from './components/create/RequestForm';

import Contact from './pages/Contact';
import Terms from './pages/Terms';

import Houses from './pages/Houses';
import Post from './pages/Post';
// G-PL7C66F1W6
import NotFound from './pages/NotFound';
import './App.css'; // Ensure to include your global styles

const App = () => {

ReactGA.initialize("G-PL7C66F1W6"); // Replace with your Measurement ID

  return (
    <Router>
      <TrackPageView />
      <Navbar /> {/* Include the Navbar */}
      <div className="main-content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/terms" element={<Terms />} />

          <Route path="/nyumba" element={<Houses />} />
          <Route path="/post" element={<Post />} />
          
          <Route exact path="/nyumbasales" element={<HouseSale />} />
          <Route path="/nyumbasales/:id" element={<HouseSaleDetails />} />
          <Route path="/nyumbasale/create" element={<HouseSaleForm />} />
          <Route path="/nyumbasale/update/:id" element={<HouseSaleForm />} />

          <Route exact path="/nyumbarent" element={<HouseRent />} />
          <Route path="/nyumbasp/:id" element={<HouseRentDetails />} />
          <Route path="/nyumbasp/create" element={<HouseRentForm />} />
          <Route path="/nyumbasp/update/:id" element={<HouseRentForm />} />

          <Route exact path="/requests" element={<Request />} />
          <Route path="/requests/:id" element={<RequestDetails />} />
          <Route path="/request/create" element={<RequestForm />} />
          <Route path="/request/update/:id" element={<RequestForm />} />
          {/* Add other routes here as needed */}

          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
};

export default App;
