import React, { useState } from 'react';
import axios from 'axios';
import './styles/RequestForm.css';

const HouseSearchRequestForm = () => {
  const [formData, setFormData] = useState({
    location: '',
    description: '',
    assistPhoneNumber: '260777210641',
    assistName: 'Nzubo',
    price: '',
    phoneNumber: '',
    numberOfBedrooms: 1,
    nameOfUser: 'Name',
    religion: 'Christian',
    latitude: -15.43617,
    longitude: 28.34214,
    typeOfHouse: 'For Rent',
    images: [],
    isActive: false,
  });

  const [imageFiles, setImageFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleFileChange = (e) => {
    setImageFiles(e.target.files);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');

    const formDataToSend = new FormData();
    Object.keys(formData).forEach((key) => {
      formDataToSend.append(key, formData[key]);
    });
    Array.from(imageFiles).forEach((file) => {
      formDataToSend.append('images', file);
    });

    try {
      const response = await axios.post('https://khah-184f534d3b81.herokuapp.com/api/request/create', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setMessage('Request submitted successfully! Go to contact for activation & verification of details.');
      setFormData({
        location: '',
        description: '',
        assistPhoneNumber: '260777210641',
        assistName: 'Nzubo',
        price: '',
        phoneNumber: '',
        numberOfBedrooms: 1,
        nameOfUser: 'Name',
        religion: 'Christian',
        latitude: -15.43617,
        longitude: 28.34214,
        typeOfHouse: 'For Rent',
        images: [],
        isActive: false,
      });
      setImageFiles([]);
    } catch (error) {
      setMessage('Error submitting request. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="form-container">
      <h2>House Search Request</h2>
      {message && <p className="message">{message}</p>}
      <form onSubmit={handleSubmit} encType="multipart/form-data" className="form">
        <div className="form-group">
          <label htmlFor="location">Location:</label>
          <input
            type="text"
            id="location"
            name="location"
            value={formData.location}
            onChange={handleChange}
            placeholder="Enter the desired location"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="description">Description:</label>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            placeholder="Provide additional details about the house"
          />
        </div>

        <div className="form-group">
          <label htmlFor="price">Price:</label>
          <input
            type="number"
            id="price"
            name="price"
            value={formData.price}
            onChange={handleChange}
            placeholder="Enter your budget"
          />
        </div>

        <div className="form-group">
          <label htmlFor="phoneNumber">Phone Number:</label>
          <input
            type="text"
            id="phoneNumber"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            placeholder="Enter your contact number"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="numberOfBedrooms">Number of Bedrooms:</label>
          <input
            type="number"
            id="numberOfBedrooms"
            name="numberOfBedrooms"
            value={formData.numberOfBedrooms}
            onChange={handleChange}
            min="1"
          />
        </div>

        <div className="form-group">
          <label htmlFor="typeOfHouse">Type of House:</label>
          <select
            id="typeOfHouse"
            name="typeOfHouse"
            value={formData.typeOfHouse}
            onChange={handleChange}
          >
            <option value="For Rent">For Rent</option>
            <option value="For Sale">For Sale</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="latitude">Latitude:</label>
          <input
            type="number"
            id="latitude"
            name="latitude"
            value={formData.latitude}
            onChange={handleChange}
            step="any"
          />
        </div>

        <div className="form-group">
          <label htmlFor="longitude">Longitude:</label>
          <input
            type="number"
            id="longitude"
            name="longitude"
            value={formData.longitude}
            onChange={handleChange}
            step="any"
          />
        </div>

        <div className="form-group">
          <label htmlFor="images">Images:</label>
          <input
            type="file"
            id="images"
            name="images"
            multiple
            onChange={handleFileChange}
          />
        </div>

        <div className="form-group checkbox-group">
          <label htmlFor="isActive">
            <input
              type="checkbox"
              id="isActive"
              name="isActive"
              checked={formData.isActive}
              onChange={handleChange}
            />
            Active Request
          </label>
        </div>

        <button type="submit" className="submit-button" disabled={loading}>
          {loading ? 'Submitting...' : 'Submit Request'}
        </button>
      </form>
    </div>
  );
};

export default HouseSearchRequestForm;
