import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { NavLink, Link } from 'react-router-dom';
import './styles/Requests.css';

const RequestsList = () => {
  const [requests, setRequests] = useState([]);
  const [filteredRequests, setFilteredRequests] = useState([]);
  const [activeTab, setActiveTab] = useState('For Rent'); // Default tab is 'For Rent'
  const [locationFilter, setLocationFilter] = useState('');
  const [maxPriceFilter, setMaxPriceFilter] = useState('');
  const [minBedroomsFilter, setMinBedroomsFilter] = useState('');

useEffect(() => {
    document.title = 'Nzubo | Request for Property';
  }, []);

  useEffect(() => {
    // Fetch all requests from the backend
    const fetchRequests = async () => {
      try {
        const response = await axios.get('https://khah-184f534d3b81.herokuapp.com/api/requests');
        setRequests(response.data);
      } catch (error) {
        console.error('Error fetching requests:', error);
      }
    };

    fetchRequests();
  }, []);

  useEffect(() => {
    // Filter requests based on the active tab, filters, and "isActive"
    const filtered = requests.filter((request) => {
      return (
        request.isActive && // Only include active requests
        request.typeOfHouse === activeTab &&
        (!locationFilter || request.location.toLowerCase().includes(locationFilter.toLowerCase())) &&
        (!maxPriceFilter || request.price <= parseFloat(maxPriceFilter)) &&
        (!minBedroomsFilter || request.numberOfBedrooms >= parseInt(minBedroomsFilter))
      );
    });
    setFilteredRequests(filtered);
  }, [requests, activeTab, locationFilter, maxPriceFilter, minBedroomsFilter]);

  return (
    <div className="requests-list-container">
      <h2>Requests for houses</h2>

      {/* Tabs for switching between "For Rent" and "For Sale" */}
      <div className="tabs">
        <button
          className={`tab ${activeTab === 'For Rent' ? 'active' : ''}`}
          onClick={() => setActiveTab('For Rent')}
        >
          For Rent
        </button>
        <button
          className={`tab ${activeTab === 'For Sale' ? 'active' : ''}`}
          onClick={() => setActiveTab('For Sale')}
        >
          For Sale
        </button>
      </div>

      {/* Filters */}
      <div className="filters">
        <input
          type="text"
          placeholder="Filter by location"
          value={locationFilter}
          onChange={(e) => setLocationFilter(e.target.value)}
        />
        <input
          type="number"
          placeholder="Maximum price"
          value={maxPriceFilter}
          onChange={(e) => setMaxPriceFilter(e.target.value)}
        />
        <input
          type="number"
          placeholder="Minimum number of bedrooms"
          value={minBedroomsFilter}
          onChange={(e) => setMinBedroomsFilter(e.target.value)}
        />
      </div>

      {/* Button to create a new rental listing */}
      <div className="create-new-request-container">
        <Link to="/request/create" className="create-new-request-button">
          + Post a Request
        </Link>
      </div>

      {/* List of filtered requests */}
      <div className="requests-list">
        {filteredRequests.length > 0 ? (
          filteredRequests.map((request) => (
            <div
              key={request._id}
              className="request-item"
              onClick={() => (window.location.href = `/request/${request._id}`)} // Navigate to details page
            >
              <h3>{request.location}</h3>
              <p>
                <strong>Price:</strong> {request.price ? `${request.price} ZMW` : 'N/A'}
              </p>
              <p>
                <strong>Bedrooms:</strong> {request.numberOfBedrooms || 'N/A'}
              </p>
              <p>
                <strong>Type:</strong> {request.typeOfHouse}
              </p>
            </div>
          ))
        ) : (
          <p>No requests found.</p>
        )}
      </div>
    </div>
  );
};

export default RequestsList;
