import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaCalendarAlt } from 'react-icons/fa';
import { Carousel } from 'react-responsive-carousel';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { FaApple, FaGooglePlay, FaHandHoldingHeart, FaPhoneAlt } from 'react-icons/fa';

import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

import { Switch, FormControlLabel, Typography } from "@mui/material";

// Fix for marker icons
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

import './styles/HouseRentForm.css';

const RentalPropertyForm = () => {
  const [formData, setFormData] = useState({
    location: '',
    price: '',
    numberOfBedrooms: 1,
    phoneNumber: '',
    availableDate: '',
    description: '',
    proximityToMalls: '',
    onRent: true,
    exclusive: false,
    nameOfManager: '',
    latitude: -15.3875, // Default to Lusaka
    longitude: 28.3228,
    rating: 3,
    typeOfNyumba: 'Stand-alone',
    likes: 0,
    dislikes: 0,
    liked: false,
    disliked: false,
  });

  const [selectedImages, setSelectedImages] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleMapClick = ({ lat, lng }) => {
    setFormData({
      ...formData,
      latitude: lat,
      longitude: lng,
    });
  };

  const MapClickHandler = () => {
    useMapEvents({
      click(e) {
        handleMapClick(e.latlng);
      },
    });
    return null;
  };

  delete L.Icon.Default.prototype._getIconUrl;

  L.Icon.Default.mergeOptions({
    iconRetinaUrl: markerIcon2x,
    iconUrl: markerIcon,
    shadowUrl: markerShadow,
  });

  const handleImageChange = (e) => {
    setSelectedImages(e.target.files);
  };

  const handleRatingChange = (value) => {
    setFormData({
      ...formData,
      rating: value,
    });
  };

  const isValidPhoneNumber = (phone) => /^\+?\d{7,15}$/.test(phone);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isValidPhoneNumber(formData.phoneNumber)) {
      alert('Please enter a valid phone number.');
      return;
    }

    setLoading(true);
    const formDataToSend = new FormData();

    if (selectedImages) {
      for (let i = 0; i < selectedImages.length; i++) {
        formDataToSend.append('images', selectedImages[i]);
      }
    }
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }

    try {
      const response = await axios.post(
        'https://khah-184f534d3b81.herokuapp.com/api/nyumbap/create',
        formDataToSend,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );
      alert(response.data.msg);
      setFormData({
        location: '',
        price: '',
        numberOfBedrooms: 1,
        phoneNumber: '',
        availableDate: '',
        description: '',
        proximityToMalls: '',
        onRent: true,
        exclusive: false,
        nameOfManager: '',
        latitude: '',
        longitude: '',
        rating: 3,
        typeOfNyumba: 'Stand-alone',
        likes: 0,
        dislikes: 0,
        liked: false,
        disliked: false,
      });
      setSelectedImages(null);
    } catch (error) {
      console.error(error);
      alert(error.response?.data?.msg || 'Error posting the rental property');
    } finally {
      setLoading(false);
    }
  };

  const imageUrls = selectedImages ? Array.from(selectedImages).map((file) => URL.createObjectURL(file)) : [];

  useEffect(() => {
    return () => {
      imageUrls.forEach((url) => URL.revokeObjectURL(url));
    };
  }, [imageUrls]);

  return (
    <form onSubmit={handleSubmit} className="private-home-form">
      <h2>List Your Rental Property</h2>
      <div>
        <label htmlFor="location">Location:</label>
        <input
          id="location"
          type="text"
          name="location"
          value={formData.location}
          onChange={handleChange}
          placeholder="Enter the location"
          required
        />
      </div>
      <div>
        <label htmlFor="description">Description:</label>
        <textarea
          id="description"
          name="description"
          value={formData.description}
          onChange={handleChange}
          placeholder="Provide a brief description"
          required
        ></textarea>
      </div>

<div>
  <label htmlFor="typeOfNyumba">Type of Property:</label>
  <select id="typeOfNyumba" name="typeOfNyumba" value={formData.typeOfNyumba} onChange={handleChange}>
    <option value="Stand-alone">Stand-alone</option>
    <option value="Flat">Flat</option>
    <option value="Apartment">Apartment</option>
    <option value="Semi-detached">Semi-detached</option>
  </select>
</div>
      <div>
        <label htmlFor="price">Price (K) / month:</label>
        <input
          id="price"
          type="number"
          name="price"
          value={formData.price}
          onChange={handleChange}
          placeholder="Enter the rental price"
          required
        />
      </div>
      <div>
        <label htmlFor="numberOfBedrooms">Number of Bedrooms:</label>
        <input
          id="numberOfBedrooms"
          type="number"
          name="numberOfBedrooms"
          value={formData.numberOfBedrooms}
          onChange={handleChange}
          min="1"
          required
        />
      </div>
      <div>
        <label htmlFor="phoneNumber">Phone Number:</label>
        <input
          id="phoneNumber"
          type="tel"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
          placeholder="Enter your contact number"
          required
        />
      </div>
      <div>
        <label htmlFor="nameOfManager">Name of Manager:</label>
        <input
          id="nameOfManager"
          name="nameOfManager"
          value={formData.nameOfManager}
          onChange={handleChange}
          placeholder="Enter Name of Manager"
          required
        />
      </div>
      <div>
        <label htmlFor="proximityToMalls">Proximity to Malls (in km):</label>
        <input
          id="proximityToMalls"
          type="number"
          name="proximityToMalls"
          value={formData.proximityToMalls}
          onChange={handleChange}
        />
      </div>
      <div className="checkbox-container">
        <label htmlFor="exclusive">
          <input
            type="checkbox"
            id="exclusive"
            name="exclusive"
            checked={formData.exclusive}
            onChange={handleChange}
          />
            Exclusive to Nzubo
        </label>
      </div>
      <div>
        <label>Choose Location:</label>
        <MapContainer
          center={[formData.latitude, formData.longitude]}
          zoom={10}
          style={{ height: '300px', width: '100%' }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution="© OpenStreetMap contributors"
          />
          <Marker position={[formData.latitude, formData.longitude]} />
          <MapClickHandler />
        </MapContainer>
        <p>
          Selected Location: Latitude {formData.latitude}, Longitude {formData.longitude}
        </p>
      </div>
      <div>
        <label htmlFor="latitude">Latitude:</label>
        <input
          id="latitude"
          type="text"
          name="latitude"
          value={formData.latitude}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label htmlFor="longitude">Longitude:</label>
        <input
          id="longitude"
          type="text"
          name="longitude"
          value={formData.longitude}
          onChange={handleChange}
          required
        />
      </div>

      <div>
        <label htmlFor="availableDate">Available Date:</label>
        <input
          id="availableDate"
          type="date"
          name="availableDate"
          value={formData.availableDate}
          onChange={handleChange}
          required
        />
        <FaCalendarAlt onClick={() => document.querySelector('#availableDate').click()} />
      </div>
      <div>
        <label>Rating:</label>
        <div className="star-rating">
          {[1, 2, 3, 4, 5].map((star) => (
            <span key={star} onClick={() => handleRatingChange(star)}>
              {star <= formData.rating ? '★' : '☆'}
            </span>
          ))}
        </div>
      </div>
      <div>
        <label>Upload Images:</label>
        <input
          type="file"
          accept="image/*"
          multiple
          onChange={handleImageChange}
          required
        />
      </div>
      {imageUrls.length > 0 && (
        <div className="image-carousel">
          <Carousel showThumbs={false} infiniteLoop autoFocus>
            {imageUrls.map((url, index) => (
              <div key={index}>
                <img src={url} alt={`Selected ${index + 1}`} />
              </div>
            ))}
          </Carousel>
        </div>
      )}
      <div className="submit-container">
        {loading ? (
          <div className="spinner"></div>
        ) : (
          <button type="submit" disabled={loading}>
            Submit
          </button>
        )}
      </div>
      <div className="download-buttons">
        <a href="https://nzubo.net/contact" target="_blank" rel="noreferrer" className="btn btn-ios">
          <FaHandHoldingHeart /> {'\u00A0'}Assistance

        </a>
        <a href="tel: +260777210641" target="_blank" rel="noreferrer" className="btn btn-android">
          <FaPhoneAlt />  {'\u00A0'}Call
        </a>
      </div>
    </form>
  );
};

export default RentalPropertyForm;
