import React, {useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faHome } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import './styles/Post.css';

const ResponsiveCards = () => {
  useEffect(() => {
    document.title = 'Nzubo | Estate';
  }, []);

  const navigate = useNavigate();

  const cards = [
    {
      id: 1,
      title: 'For Rent',
      description: 'Post a rental.',
      icon: faBuilding,
      route: '/nyumbasp/create',
    },
    {
      id: 2,
      title: 'For Sale',
      description: 'Sale a house.',
      icon: faHome,
      route: '/nyumbasale/create',
    },
  ];

  const handleNavigate = (route) => {
    navigate(route);
  };

  return (
    <div className="responsive-cards-container">
      {cards.map((card) => (
        <div
          key={card.id}
          className="responsive-card"
          onClick={() => handleNavigate(card.route)}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => e.key === 'Enter' && handleNavigate(card.route)}
        >
          <div className="responsive-card-icon2">
            <FontAwesomeIcon icon={card.icon} />
          </div>
          <div className="responsive-card-content">
            <h3>{card.title}</h3>
            <p>{card.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ResponsiveCards;
