import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'; // To extract the request ID from the URL
import axios from 'axios';
import './styles/RequestDetails.css'; // CSS for styling

const RequestDetails = () => {
  const { id } = useParams(); // Extract the request ID from the route
  const [request, setRequest] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch the request details from the backend
    const fetchRequestDetails = async () => {
      try {
        const response = await axios.get(`/api/requests/${id}`);
        setRequest(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching request details:', err);
        setError('Failed to load request details.');
        setLoading(false);
      }
    };

    fetchRequestDetails();
  }, [id]);

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="request-details-container">
      <h2>Request Details</h2>
      {request ? (
        <div className="request-details-card">
          <p>
            <strong>Location:</strong> {request.location || 'N/A'}
          </p>
          <p>
            <strong>Description:</strong> {request.description || 'N/A'}
          </p>
          <p>
            <strong>Price:</strong> {request.price ? `${request.price} ZMW` : 'N/A'}
          </p>
          <p>
            <strong>Phone Number:</strong> {request.phoneNumber || 'N/A'}
          </p>
          <p>
            <strong>Number of Bedrooms:</strong> {request.numberOfBedrooms || 'N/A'}
          </p>
          <p>
            <strong>Name of User:</strong> {request.nameOfUser || 'N/A'}
          </p>
          <p>
            <strong>Religion:</strong> {request.religion || 'N/A'}
          </p>
          <p>
            <strong>Latitude:</strong> {request.latitude || 'N/A'}
          </p>
          <p>
            <strong>Longitude:</strong> {request.longitude || 'N/A'}
          </p>
          <p>
            <strong>Type of House:</strong> {request.typeOfHouse || 'N/A'}
          </p>
          <p>
            <strong>Is Active:</strong> {request.isActive ? 'Yes' : 'No'}
          </p>
          <p>
            <strong>Assist Phone Number:</strong> {request.assistPhoneNumber || 'N/A'}
          </p>
          <p>
            <strong>Assist Name:</strong> {request.assistName || 'N/A'}
          </p>
          <div>
            <strong>Images:</strong>
            <div className="request-images">
              {request.images && request.images.length > 0 ? (
                request.images.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Request ${index + 1}`}
                    className="request-image"
                  />
                ))
              ) : (
                <p>No images available</p>
              )}
            </div>
          </div>
        </div>
      ) : (
        <p>No details found for this request.</p>
      )}
    </div>
  );
};

export default RequestDetails;
