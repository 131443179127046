import React, { useState } from 'react';
import './Navbar.css'; // Import the CSS file for the navbar
import Logo from '../assets/images/logo.png';
import { NavLink } from 'react-router-dom';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <NavLink to="/" className="logo-link">
          <img src={Logo} alt="Logo" className="logo" />
          <span className="brand-name">Nzubo</span>
        </NavLink>
      </div>

      <ul className={`navbar-links ${isOpen ? 'active' : ''}`}>
        <li><NavLink to="/" onClick={toggleMenu} activeClassName="active">Home</NavLink></li>
        <li><NavLink to="/nyumba" onClick={toggleMenu} activeClassName="active">Property</NavLink></li>
        {/* <li><NavLink to="/requests" onClick={toggleMenu} activeClassName="active">Looking For?</NavLink></li> */}
        <li><NavLink to="/post" onClick={toggleMenu} activeClassName="active">+ Upload</NavLink></li>
        {/* 
        <li><NavLink to="/nyumbasales" onClick={toggleMenu} activeClassName="active">For Sale</NavLink></li>
        <li><NavLink to="/nyumbarent" onClick={toggleMenu} activeClassName="active">For Rent</NavLink></li> */}
        <li><NavLink to="/contact" onClick={toggleMenu} activeClassName="active">Contact</NavLink></li>
        <li><NavLink to="/about" onClick={toggleMenu} activeClassName="active">About</NavLink></li>
        <li><NavLink to="/terms" onClick={toggleMenu} activeClassName="active">Terms</NavLink></li>
      </ul>

      {/* Modified Hamburger menu for mobile view */}
      <div className={`hamburger ${isOpen ? 'active' : ''}`} onClick={toggleMenu}>
        <span className={`line line1 ${isOpen ? 'cross' : ''}`}></span>
        <span className={`line line2 ${isOpen ? 'cross' : ''}`}></span>
      </div>
    </nav>
  );
};

export default Navbar;
